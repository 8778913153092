import React, { useRef } from 'react';
import './App.css';
import RouteConfig from './router';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';

function App() {
  const homeRefs = {
    carBrandingRef: useRef(null),
    billboardRef: useRef(null),
    ledScreensRef: useRef(null),
    branding3DRef: useRef(null),
  };

  const scrollToSection = (section) => {
    if (homeRefs[section]?.current) {
      homeRefs[section].current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Header scrollToSection={scrollToSection} />
      <RouteConfig homeRefs={homeRefs} />
      <Footer />
    </>
  );
}

export default App;
