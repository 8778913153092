import React, { useState, useEffect } from 'react';
import { Sling as Hamburger } from 'hamburger-react';
import styles from "./header.module.css";

const Header = ({ scrollToSection }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const isScrollingUp = prevScrollPos > currentScrollPos;

      setVisible(isScrollingUp || currentScrollPos < 50);
      setScrolled(currentScrollPos > 50);
      setPrevScrollPos(currentScrollPos);

      // Close the hamburger menu when scrolling on mobile
      if (isOpen) {
        setIsOpen(false); // Close menu
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isOpen, prevScrollPos]);

  const handleSearchToggle = () => {
    setSearchVisible(!searchVisible);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Disable scrolling when the mobile menu is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  // New function to handle navigation click
  const handleNavClick = (section) => {
    scrollToSection(section); // Call your existing scroll function
    setIsOpen(false); // Close the hamburger menu
  };

  return (
    <>
      {/* Header section */}
      <header
        className={`${styles.header} ${scrolled ? styles.scrolled : ''} ${visible ? styles.visible : styles.hidden}`}
      >
        <div className={styles.navDis}>
          <div className={styles.leftSide}>
            <div className={styles.logo}>
              <img src='/assets/images/logoo.png' alt="Logo" />
            </div>
            <ul className={styles.desktopMenu}>
              <li><a href="#services">Help</a></li>
            </ul>
          </div>
          <ul className={styles.desktopMenu}>
            <li><a onClick={() => handleNavClick('carBrandingRef')}>Car Branding</a></li>
            <li><a onClick={() => handleNavClick('billboardRef')}>Update Billboard</a></li>
            <li><a onClick={() => handleNavClick('ledScreensRef')}>Explore LED Screens</a></li>
            <li><a onClick={() => handleNavClick('branding3DRef')}>3D Branding</a></li>
            <li><a href="#contact" className={styles.contactBtn}>Contact</a></li>
            <li><a href="#help">Help</a></li>
          </ul>
          <div className={styles.hamburger}>
            <Hamburger toggled={isOpen} toggle={setIsOpen} />
          </div>
        </div>
      </header>

      {/* Navigation outside the header for mobile view */}
      <nav className={`${styles.nav} ${isOpen ? styles.open : ''}`}>
      <ul >
            <li><a onClick={() => handleNavClick('carBrandingRef')}>Car Branding</a></li>
            <li><a onClick={() => handleNavClick('billboardRef')}>Update Billboard</a></li>
            <li><a onClick={() => handleNavClick('ledScreensRef')}>Explore LED Screens</a></li>
            <li><a onClick={() => handleNavClick('branding3DRef')}>3D Branding</a></li>
            <li><a href="#contact" className={styles.contactBtn}>Contact</a></li>
          </ul>
        {searchVisible && (
          <input
            type="text"
            className={styles.searchInput}
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        )}
      </nav>
    </>
  );
};

export default Header;
