import React, { useState, useRef } from 'react';
import styles from './FAQ.module.css'; // Import the CSS module

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null); // Reference to the content (answer) for dynamic height

  return (
       <div className='subContainer'>
        <div className={styles.faqItem}>
      <div className={styles.faqQuestion} onClick={() => setIsOpen(!isOpen)}>
        {question}
        <span className={`${styles.faqToggle} ${isOpen ? styles.open : ''}`}>
          {isOpen ? '-' : '+'} {/* Change between + and - */}
        </span>
      </div>
      <div
        ref={contentRef}
        className={styles.faqAnswerWrapper}
        style={
          isOpen
            ? { height: contentRef.current?.scrollHeight + 'px' }
            : { height: '0px' }
        }
      >
        <div className={styles.faqAnswer}>{answer}</div>
      </div>
    </div>
       </div>
  );
};

const FAQ = () => {
  const faqData = [
    // Car Branding
    { question: 'What is Car Branding?', answer: 'Car Branding transforms your vehicle into a mobile advertisement, customized with designs that reflect your brand.' },
    { question: 'How durable is the vehicle wrap?', answer: 'Our vehicle wraps are made from high-quality materials that are designed to withstand various weather conditions.' },
    { question: 'Can I remove or change the wrap later?', answer: 'Yes, car wraps can be removed or updated without damaging the original paint.' },
  
    // Billboard/Totem Advertising
    { question: 'What is Billboard/Totem Advertising?', answer: 'This form of advertising uses large-scale visuals to capture attention in high-traffic areas.' },
    { question: 'How effective is billboard advertising?', answer: 'Billboards are highly visible and reach a wide audience, making them effective for brand awareness.' },
    { question: 'Are eco-friendly materials available?', answer: 'Yes, we offer sustainable options for billboards to support greener advertising practices.' },
  
    // 3D Signage
    { question: 'What is 3D Signage?', answer: '3D Signage uses dimensional signs to create impactful visual displays that stand out.' },
    { question: 'How is 3D signage installed?', answer: 'Our team ensures secure installation, and we offer both indoor and outdoor options.' },
    { question: 'Can 3D signage include interactive elements?', answer: 'Yes, we offer options such as holographic and augmented reality features.' },
  
    // LED Screens
    { question: 'What are LED Screens used for?', answer: 'LED screens are used to display dynamic and high-resolution content in both indoor and outdoor spaces.' },
    { question: 'Are LED screens suitable for outdoor use?', answer: 'Yes, our LED screens are weather-resistant and offer bright, clear visuals for outdoor events.' },
    { question: 'Can I update content on the LED screen remotely?', answer: 'Yes, content can be managed and updated remotely with compatible software.' }
  ];
  
  return (
    <div className={styles.faq}>
      <h2 className={styles.heading}>Frequent questions</h2>
      {faqData.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

export default FAQ;
