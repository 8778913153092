import React from "react";
import style from "./Before.module.css"

const Before = () =>{
    return(
        <div className={`subContainer ${style.beforeSection}`}>
            <h3>Transform your vision into reality.
            </h3>
               <div className={style.beforeDis}>

               <div className={style.before}>
              <img src="/assets/images/beff.png" alt="#" />
              </div>
              <img src="/assets/images/arrow.png" alt="#" className={style.arrow}/>
              <div  className={style.after}>
              {/* <img src="/assets/images/bill.webp" alt="#" /> */}
              <iframe width="560" height="315" src="https://www.youtube.com/embed/9Kw4BO93o4k?autoplay=1&loop=1&mute=1&controls=0&playlist=9Kw4BO93o4k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>


              </div>
               </div>
        </div>
    )
}

export default Before;