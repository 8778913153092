import React from 'react';
import styles from './LineAnimation.module.css'; // Import the CSS module

const LineAnimation = () => {
    return (
        <div className={styles.animationContainer}>
            <div className={`${styles.line} ${styles.line1}`}></div>
            <div className={`${styles.line} ${styles.line2}`}></div>
            <div className={`${styles.line} ${styles.line3}`}></div>
            <div className={`${styles.line} ${styles.line4}`}></div>
            <div className={`${styles.line} ${styles.line5}`}></div>
            <div className={`${styles.line} ${styles.line6}`}></div>
            
            {/* <div className={`${styles.line} ${styles.line7}`}></div> */}
        </div>
    );
};

export default LineAnimation;
