import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/home/Home";

const RouteConfig = ({ homeRefs }) => {
  return (
    <Routes>
      <Route path="/" element={<Home homeRefs={homeRefs} />} />
    </Routes>
  );
};

export default RouteConfig;
