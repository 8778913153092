import React, { useRef } from "react";
import styles from "./Home.module.css";
import Lottie from "react-lottie-player";

import rubik from "../../lottie/rubik.json";
import { Link } from "react-router-dom";
import Before from "../../components/before/Before";
import DesignShowcase from "../../components/designShowcase/DesignShowcase";
// import LineAnimation from "../../components/lineAnimations/LineAnimation";
import LineAnimationTwo from "../../components/lineAnimationsTwo/LineAnimationTwo";
import LineAnimation from "../../components/lineAnimations/LineAnimation";
import FAQ from "../../components/faq/Faq";
import Features1 from "../../components/featured/Featured";

const Home = ({ homeRefs }) => {

  const carBrandingRef = useRef(null);
  const billboardRef = useRef(null);
  const ledScreensRef = useRef(null);
  const branding3DRef = useRef(null);
  return (
    <>
      <div className={styles.homeBck}>
        <LineAnimation/>
        <LineAnimationTwo/>
        <div className={styles.homeBanner}>
          {/* <h1>BOLD</h1> */}
          <img src="/assets/images/logoo.png" className={styles.mainLogos}/>
          <span>STUDIO</span>
          <p>
            At PrintSphere, we combine innovation and precision to bring your ideas to life
            through high-quality prints and 3D advertising solutions. Elevate your brand
            with eye-catching designs and impactful visuals for any project, from marketing
            materials to dynamic 3D displays.
          </p>
          <div className={styles.homeButtons}>
            <Link to="#">OUR JOBS</Link>
            <Link to="#">CATALOG</Link>
          </div>
          <div className="rubik">
            <Lottie
              loop
              animationData={rubik}
              play
              className="lottieContainer"
              style={{ width: 450, height: 450 }}
            />
          </div>
        </div>
      </div>
      <Features1/>
      <Before />
      <div className={styles.callUs}>
        <div className="subContainer">
        <h2>Ready to start your project?
        </h2>
        <a href="">Call Us</a>
        </div>
      </div>
      <div ref={homeRefs.carBrandingRef}>
        <DesignShowcase
          image="/assets/images/cars.png"
          title="Transform Your Vehicle with Custom Car Branding"
          text="Bold offers professional car branding to turn your vehicle into a moving advertisement..."
          keyOne="Custom Wraps – Eye-catching vehicle wraps tailored to your brand."
          keyTwo="Fleet Solutions – Consistent branding across all vehicles."
          keyThree="Reflective Wraps – Standout designs for 24/7 visibility."
        />
      </div>

      <div ref={homeRefs.billboardRef}>
        <DesignShowcase
          reverse="reverse"
          image="/assets/images/bill.jpeg"
          title="Make a Bold Statement with Eye-Catching Billboards"
          text="Make a big impression with Bold’s high-impact billboard designs..."
          keyOne="High-Res Print Billboards – Clear, vibrant designs for max visibility."
          keyTwo="Digital Billboards – Programmable displays for dynamic messaging."
          keyThree="Eco-Friendly Options – Sustainable materials for greener advertising."
        />
      </div>

      <div ref={homeRefs.ledScreensRef}>
        <DesignShowcase
          image="/assets/images/led.jpg"
          title="Light Up Your Experience!"
          text="Elevate your space with cutting-edge LED displays..."
          keyOne="Outdoor LED – Bright, weather-resistant displays."
          keyTwo="Indoor LED Walls – High-res screens for immersive experiences."
          keyThree="Mobile LED Trailers – Portable screens for events and promos."
        />
      </div>

      <div ref={homeRefs.branding3DRef}>
        <DesignShowcase
          reverse="reverse"
          image="/assets/images/plex.png"
          title="Elevate Your Space with Striking 3D Signage"
          text="These titles are designed to be engaging and descriptive..."
          keyOne="AR Integration – Interactive, virtual elements with real-world impact."
          keyTwo="Holographic Displays – 3D holograms for eye-catching visuals."
          keyThree="Projection Mapping – Transform spaces with captivating 3D visuals."
        />
      </div>
      <FAQ/>

   
    </>
  );
};

export default Home;
